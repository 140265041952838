.cases {
  background-color: var(--color-black);
  color: var(--color-white);
}

.introduction {
  padding: var(--size-100) var(--size-25) 0;

  & > * {
    width: 100%;
    max-width: var(--container-md);
    margin-inline: auto;
  }
}

.contact {
  overflow: hidden;
  padding-block: var(--size-60);

  @media (--viewport-md) {
    padding-block: var(--size-200) 250px;
  }
}

.contentAndContact {
  padding-inline: var(--size-25);
  padding-bottom: var(--size-50);

  @media (--viewport-md) {
    padding-bottom: var(--size-100);
  }

  & > .contentAndContactLayout {
    width: 100%;
    max-width: var(--container-xl);
    margin-inline: auto;
  }
}

.componentContentAndContact {
  display: flex;
  flex-direction: column;
  gap: var(--size-30);

  @media (--viewport-lg) {
    flex-direction: row;
    gap: var(--size-80);
  }

  & > .contentLayout {
    margin-bottom: var(--size-60);

    @media (--viewport-lg) {
      margin-bottom: unset;
      max-width: 1020px;
    }
  }

  & > .contactBlockWrapper {
    flex-shrink: 0;

    @media (--viewport-lg) {
      max-width: 450px;
      margin-right: var(--size-40);
    }

    @media (--viewport-xxl) {
      margin-right: 0;
    }
  }
}

.contactBlockWrapper {
  position: relative;

  & > * {
    top: var(--size-200);
  }
}

.componentContactBlockSmall {
  background-color: var(--color-pink);
  color: var(--color-gray-90);
  font-size: var(--font-size-32-40);
  line-height: var(--line-height-compact-md);
  font-weight: var(--font-weight-base-500);
  padding: var(--size-30);
  position: sticky;

  @media (--viewport-xxl) {
    padding: var(--size-40);
  }

  & > .iconLayout {
    position: absolute;
    top: calc(-1 * var(--size-90));
    right: 0;
    width: 30%;
  }

  & > .icons {
    margin-top: var(--size-30);
  }
}

.icons {
  display: flex;
  gap: var(--size-10);

  & > .icon {
    width: var(--size-70);
    height: var(--size-70);
  }
}

.icon {
  background-color: var(--color-black);
  color: var(--color-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: var(--color-black);
    transition: transform 0.2s ease-out;
    z-index: -1;
  }

  &:hover {
    opacity: 1;

    &::before {
      transform: scale(1.05);
    }
  }
}
