.component {
  padding: 75px 0;
  position: relative;
  padding-block: var(--size-50);

  @media (--viewport-md) {
    padding-block: var(--size-100) var(--size-50);
  }

  & > .headingWrapper {
    @media (--viewport-md) {
      width: 100%;
      margin-bottom: calc(-1 * var(--size-60));
    }
  }
}

.carousel {
  overflow: visible;
}

.headingWrapper {
  overflow: hidden;

  & > .heading {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }
}

.linkButtonWrapper {
  display: flex;
  justify-content: center;
  padding: var(--size-30) var(--size-40);

  @media (--viewport-md) {
    justify-content: flex-end;
  }

  & > .buttonLayout {
    @media not  (--viewport-md) {
    width: 100%;
    }
  }
}

.heading {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: var(--line-height-compact);
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;
  font-size: var(--font-size-80);
  font-size: var(--font-size-80-300);
  padding: 0 var(--size-20) !important;
  user-select: none;

  @media (--viewport-md) {
    white-space: nowrap;
    letter-spacing: var(--letter-spacing-compact-xl);
  }
}

.componentDragCarousel {
  font-family: var(--font-family-base);
  padding: 0 var(--size-40);

  & > .layout {
    width: 100%;
    max-width: var(--container-lg);
    margin: 0 auto;

    @media (min-width: 1800px) {
      max-width: var(--container-xxl);
    }
  }
}

.layout {
  display: grid;
  gap: var(--size-30);
  position: relative;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.componentImage {
  position: relative;

  &::after {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  & > .coverImageLayout {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.item {
  z-index: 0;
  position: relative;
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }

  & > .imageLayout {
    height: 70%;
  }

  & > .text {
    z-index: 1;
    margin-top: 50%;
  }

  & > .buttonLayout {
    justify-self: end;
    margin: var(--size-10);
  }
}

.text {
  padding: 0 var(--size-20);
}

.client {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-base-400);
}

.title {
  font-size: var(--font-size-28);
  font-weight: var(--font-weight-condensed-400);
  font-family: var(--font-family-condensed);
  line-height: 1.1;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    cursor: none;
  }
}
